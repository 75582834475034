import React, { useEffect, useRef } from 'react';
import img from '../../assets/userlogo.png'

const Head = ({ data }) => {

  if (!data || Object.keys(data).length === 0) {
    return <div>Loading...</div>; // or some other placeholder
  }
  return (
    <div className='card px-3 py-2 rounded-0'>
      <div className='card-body p-1'>
        <div className='row d-flex align-items-center '>
          <div className='col-lg-8'>
            <div className='d-block d-sm-flex align-items-center'>
              <div className="me-2 mb-2" >
                
                <img src={img} alt="profile_image" className="border-radius-lg shadow-sm" style={{ width: "70px" }} />
              </div>
              <div className="h-100">
                <p className="m-0 fw-bold">
                  {data.name}
                  <p>
                    
                  </p>
                </p>
                <p className="mb-0 font-weight-bold " style={{ fontSize: '.8rem' }}>
                  <span>Id penyewa:{data.no_akaun_air || "N/A"}</span> | <span>Nomber Telefon:{data.no_telephone || "N/A"}</span><br/>
                  <span>Email: {data.email || "N/A"}</span> | <span>Address: {data.alamat_unit || "N/A"}</span>
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-4 mt-3 mt-lg-0'>
            <div id="sidenav-scrollbar" className='bg-light rounded' >
              <ul className="nav nav-pills nav-fill p-1 d-block d-xl-flex" role="tablist">
                
                  <li className="nav-item" >
                    <a className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" data-bs-toggle="tab" href="#" role="tab" aria-selected="true">
                      <i className=" fas fa-user"></i>
                      <span className="ms-2 text-sm">Profil</span>
                    </a>
                  </li>

                  <li className="nav-item mx-1" >
                    <a className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" data-bs-toggle="tab" href="#" role="tab" aria-selected="true">
                    <i className="fa-solid fa-envelope"></i>
                      <span className="ms-2 text-sm">Mesej</span>
                    </a>
                  </li>

                  <li className="nav-item" >
                    <a className="nav-link mb-0 px-0 py-1 d-flex align-items-center justify-content-center" data-bs-toggle="tab" href="#" role="tab" aria-selected="true">
                    <i className="fa-solid fa-database"></i>
                      <span className="ms-2 text-sm">Laporan Kawangan </span>
                    </a>
                  </li>
                 </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Head;
