import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import img from '../../assets/logo.png';
import axios from 'axios';
import SubSideBar from './SubSidebar';

import UploaderLinks from './UploaderLinks';

import Link from './Link';


const SideBar = ({ mainTitle, setMainTitle ,id}) => {
  const [subMenu, setSubMenu] = useState(false)
  const [expandedItems, setExpandedItems] = useState({});
  const [data, setData] = useState(null)
  const [barData, setBarData] = useState(null)
  const [sewa, setSewa] = useState(null)
  const [title, setTitle] = useState(null)
  const [area, setArea] = useState(null)


  const toggleItem = (itemKey) => {
    setExpandedItems((prevItems) => ({
      ...prevItems,
      [itemKey]: !prevItems[itemKey] // Toggle only the specific item
    }));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(process.env.PUBLIC_URL +'/db/user1.json')

        setBarData(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])


  useEffect(() => {
    async function fetchSewaData() {
      try {
        const response = await axios.get(process.env.PUBLIC_URL +'/db/sewa.json')
        setSewa(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchSewaData()
  }, [])

  useEffect(() => {
    if (!subMenu) {
      document.getElementById('add').style.transform = "translateX(-700px)"
    } else {
      document.getElementById('add').style.transform = "translateX(0)"

    }
  }, [subMenu])


  return (
    <div
      id="bdSidebar"
      className="d-flex ms-2 mt-2 flex-column flex-shrink-0  offcanvas-xl offcanvas-start"
      style={{ width: '250px', minHeight: "97vh", borderRadius: '20px', color: "black" }}
    >
      <div style={{ position: "relative" }}>
        <SubSideBar setSubMenu={setSubMenu} data={data} title={title} area={area} mainTitle={mainTitle} id={id} />

        {/* logo */}
        <NavLink to="/" className="navbar-brand ps-3 pt-3 pb-2">
          <img src={img} className="m-2" style={{ width: '40%' }} alt="Logo" />
          <p className="fw-bold h6 ms-4" style={{ color: 'black' }}>PERUMAHAN</p>
        </NavLink>
        <div style={{ maxHeight: "80vh", overflow: 'auto' }}>
          <ul className="nav nav-pills  flex-column mynav px-2">
            {/* Dashboard item */}
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  toggleItem(1)
                
                }}
              >
                <span className="fw-light d-flex align-items-center">
                  <i className="me-2 fas fa-home" style={{ color: 'orange' }}></i>
                  <span>HALAMAN UTAMA</span>
                </span>
                <i
                  className={`fas ${expandedItems[1] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                  style={{ fontSize: '.7rem' }}
                ></i>
              </p>
              {expandedItems[1] && (
                <ul className="nav flex-column ms-3">
                  {/* home Air link */}
                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="h1"
                    linkName="Air" setMainTitle={setMainTitle} titleName='utama'
                  />
                  {/* SEWA*/}
                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={sewa} itemKey="h2"
                    linkName="Sewa" setMainTitle={setMainTitle} titleName='utama'
                  />
                </ul>
              )}
            </li>

            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  toggleItem('b1')
                }}
              >
                <span className="fw-light d-flex align-items-center">
                  <i className="me-2 fas fa-user" style={{ color: 'orange', }}></i>
                  <span style={{textTransform: "uppercase"}}> Profil Penghuni</span>
                </span>
                <i
                  className={`fas ${expandedItems['b1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                  style={{ fontSize: '.7rem' }}
                ></i>
              </p>
              {expandedItems['b1'] && (
                <ul className="nav flex-column ms-3">
                  {/* home Air link */}
                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="c1"
                    linkName="Air" setMainTitle={setMainTitle} titleName='customers'
                  />
                  {/* SEWA*/}
                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={sewa} itemKey="c2"
                    linkName="Sewa" setMainTitle={setMainTitle} titleName='customers'
                  />

                </ul>)}
            </li>

            {/* Status */}

            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  toggleItem('c1')
                  setMainTitle('status')
                }}
              >
                <span className="fw-light">
                  <i className="me-2 fa fa-check-circle" style={{ color: "orange" }}></i>STATUS AKAUN
                </span>
                <i
                  className={`fas ${expandedItems['c1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                  style={{ fontSize: '.7rem' }}
                ></i>
              </p>
              {expandedItems['c1'] && (
                <ul className="nav flex-column ms-3" style={{color: "black"}}>


                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="s1"
                    linkName="Sewa"  setMainTitle={setMainTitle} titleName='status'
                  />

                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={sewa} itemKey="s2"
                    linkName="Jual"  setMainTitle={setMainTitle} titleName='status'
                  />

                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="s3"
                    linkName="Kosong"  setMainTitle={setMainTitle} titleName='status'
                  />
                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="s4"
                    linkName="Rampas"  setMainTitle={setMainTitle} titleName='status'
                  />

                </ul>)}
            </li>
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  toggleItem('d1')
                  setMainTitle('report')
                }}
              >
                <span className="fw-light">
                  <i className="me-2 fa-solid fa-chart-line" style={{ color: 'orange' }}></i>REPORT
                </span>
                <i
                  className={`fas ${expandedItems['d1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                  style={{ fontSize: '.7rem' }}
                ></i>
              </p>
              {expandedItems['d1'] && (
                <ul className="nav flex-column ms-3">

                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={barData} itemKey="r1"
                    linkName="Air"  setMainTitle={setMainTitle} titleName='report'
                  />

                  <Link expandedItems={expandedItems} toggleItem={toggleItem} setSubMenu={setSubMenu}
                    setArea={setArea} setTitle={setTitle} setData={setData} barData={sewa} itemKey="r2"
                    linkName="Sewa"  setMainTitle={setMainTitle} titleName='report'
                  />

                </ul>)}
            </li>

            {/* Kai links */}
            <li className="nav-item">
              <p
                className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
                style={{ color: 'black', cursor: 'pointer' }}
                onClick={() => {
                  toggleItem('e1')
                  setMainTitle('kai')
                }}
              >
                <span className="fw-light">
                  <i className="me-2 fa-solid fa-magnifying-glass-chart" style={{ color: 'orange' }}></i>KPI
                </span>
                <i
                  className={`fas ${expandedItems['e1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
                  style={{ fontSize: '.7rem' }}
                ></i>
              </p>
              {expandedItems['e1'] && (
                <ul className="nav flex-column ms-3">
               {/* Enter kpi links */}
                   <NavLink to="/kpi/1"><span className='fas fa-water' style={{color: "orange"}}></span> AIR</NavLink>
                   <NavLink to="/kpi/2"><span className='fas fa-house' style={{color: "orange"}}></span> SEWA</NavLink>
                </ul>)}
            </li>

            {/* Uploader links */}
            <UploaderLinks toggleItem={toggleItem} expandedItems={expandedItems} setMainTitle={setMainTitle} />

          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
