import React from 'react';
const formatter = new Intl.NumberFormat('en-US');

const SliderCard = ({
  title,
  water = {}, // Default to an empty object to avoid undefined issues
  rental = {}, // Same for rental
}) => {
//  fow water
  const waterDate = water?.uploadDate;
const newDate = new Date(waterDate).toLocaleDateString('en-GB'); 
// for rental
const rentalDate = rental?.uploadDate;
const newDateR = new Date(rentalDate).toLocaleDateString('en-GB'); 

  return (
    <div className="card mx-1 shadow-none" style={{color: "black"}}>
      <div className="card-body p-0">
        <div className="row">
          <div className="col-lg-2 mb-3 mb-lg-0">
            <div className="card bg-light" style={{ height: "100%" }}>
              <div className="card-body p-0">
                <h3 className="card-title text-start text-md">{title}</h3>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div className="card p-2">
              <div className="card-body p-0 px-2">
                <div className="row">
                  {/* Water Information */}
                  <div className="col-sm-6 col-md-4 text-start">
                    <span className="d-flex justify-content-between align-items-center">
                      <h4 className="font" style={{color:"#5e72e4"}}>AIR</h4>
                      <p className="text-sm text-bold me-3 mt-1" style={{color: "black"}}>{water?.date}</p>
                    </span>
                    <p className="fw-bolder fs-5 m-0">
                      {water?.total || 0} 
                      <span className="fw-bolder m-0  mx-2" >
                        ({formatter.format(water?.totalAccounts) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-success font">AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                      {water?.totalActive || 0}
                      <span className="fw-bolder m-0  mx-2" >
                        ({formatter.format(water?.totalAkaunAktif) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font">TIDAK AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                      {water?.totalNonActive || 0}
                      <span className="fw-bolder m-0  mx-2" >
                        ({formatter.format(water?.totalAkaunTidakAktif) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Rental Information */}
            <div className="card p-2 mt-3" style={{color:"black"}}>
              <div className="card-body p-0 px-2">
                <div className="row">
                  <div className="col-sm-6 col-md-4 text-start">
                    <span className="d-flex justify-content-between align-items-center">
                    <h4 className="font" style={{color: "orange"}}>SEWA</h4>
                    <p className="text-sm text-bold me-3 mt-1" style={{color: "black"}}>{rental.date}</p>
                    </span>
                    <p className="fw-bolder fs-5 m-0">
                      {rental?.total || 0}
                      <span className="fw-bolder m-0  mx-2" >
                        
                        ({formatter.format(rental?.totalAccounts) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-success font">AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                      {rental?.totalActive || 0}
                      <span className="fw-bolder m-0  mx-2" >
                        ({formatter.format(rental?.totalAkaunAktif) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-6 col-md-4 text-start">
                    <h4 className="text-danger font">TIDAK AKTIF</h4>
                    <p className="fw-bolder fs-5 m-0">
                      {rental?.totalNonActive || 0}
                      <span className="fw-bolder m-0  mx-2" >
                      ({formatter.format(rental?.totalAkaunTidakAktif) || 0} AKAUN)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
