import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register the required scales and components
ChartJS.register(
  CategoryScale,   // Register 'category' scale
  LinearScale,     // For the y-axis (linear scale)
  BarElement,      // Bar element for rendering bar charts
  Title,
  Tooltip,
  Legend
);

const BarChart = ({ data = [], data1 = [] }) => {

  // Helper function to parse each value in the dataset
  const parseValue = (value) => {
    if (typeof value === 'string') {
      return parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0;
    }
    return typeof value === 'number' ? value : 0;
  };

  // Ensure data and data1 are arrays before using map
  const parsedData = Array.isArray(data) ? data.map(parseValue) : [];
  const parsedData1 = Array.isArray(data1) ? data1.map(parseValue) : [];

  // Assign the parsed data for all months (filling 0 for undefined values)
  const chartDataRental = Array(12).fill(0).map((_, index) => parsedData[index] || 0);
  const chartDataWater = Array(12).fill(0).map((_, index) => parsedData1[index] || 0);

  const chartConfig = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: 'Air',
        data: chartDataRental, // Use parsed data for rental
        backgroundColor: '#5e72e4',
        borderColor: '#5e72e4',
        borderWidth: 1,
        hoverBackgroundColor: '#324cdd',
        hoverBorderColor: '#324cdd',
      },
      {
        label: 'Sewa',
        data: chartDataWater, // Use parsed data for water
        

        backgroundColor: 'orange',
        borderColor: 'orange',
        borderWidth: 1,
        hoverBackgroundColor: '#f4365c',
        hoverBorderColor: '#f4365c',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { grid: { display: false } },
      y: { grid: { color: '#e9e9e9' }, beginAtZero: true },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Total: ${tooltipItem.raw}`,
        }
      },
      legend: { display: true },
    },
  };

  return (
    <div style={{ height: '300px' }}>
      <Bar data={chartConfig} options={options} />
    </div>
  );
};

export default BarChart;
