import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import axios from "axios";
import Table from "../components/zone/Table";
import Card from "../components/zone/Card";

import IMGCard from "../components/zone/IMGCard";
import { useParams } from "react-router-dom";
import BarChart from "../components/Index/KawasanBarChart";
import Table2 from "../components/zone/Table2";


const Zone = () => {
  const location = useLocation()
  console.log(location.state)



  const { id } = useParams()

  const [card, setCard] = useState()

  useEffect(() => {

    async function getAllCustomersKawasan() {
      try {
        const response = await axios.post('http://bpohasil.click:8080/ca/getallkawasancustomers', {
          version: location.state.area,
          type: location.state.title,
          kawasan_id: location.state.kawasan_no
        });
        console.log(response.data);
        setCard(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    getAllCustomersKawasan();
  }, [location.state]);


  console.log(id)
  return (
    <div className='container-fluid  p-2 p-lg-0'>

      {/* cards */}
      <div className='row g-3'>
        <div className='col-md-6 col-lg-3'>
          {card && <Card title="Jumlah Amount" value={card.total_amount} icon="fas fa-user" color=" rgb(94, 114, 227)" />}
        </div>

        <div className='col-md-6 col-lg-3'>
          {card && <Card title="Jumlah Pelanggan Aktif" value={card.total_amount_active} icon="fas fa-user" color=" rgb(210, 117, 233)" />}
        </div>

        <div className='col-md-6 col-lg-3'>
          {card && <Card title="Jumlah Pelanggan Tidak Aktif" value={card.total_amount_non_active} icon="fas fa-user" color="orange" />}
        </div>

        <div className='col-md-6 col-lg-3'>
          {card && <Card title="Jumlah Pelanggan" value={card.count} icon="fas fa-user" color=" rgb(129, 27, 197)" />}
        </div>
      </div>

      {/* line charts */}
      <div className='row my-1 g-3'>
        <div className=' mb-3 mt-1  mb-lg-0'>
          <div className="row g-3">
            <div className="col-lg-7">
              <div className='card p-0 shadow' style={{ maxWidth: '99%' }}>
                <div className='card-body p-2'>
                  <p className="ms-2 m-0 fw-bold">{location.state?.area}- Jumlah {location.state?.title}</p>
                  <div className="pe-1">
                    <BarChart data={card?.active_type_amount || {}} data1={card?.non_active_amount || {}} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <IMGCard />
            </div>
          </div>
        </div>

      </div>
      {/* table & Progress */}
      <div className="row mt-1 g-3">
        <div className="col-lg-12">
          {/* <Table/> */}
          <div className="card px-1 py-2" style={{ minHeight: "40vh" }}>
            <p className="ms-2 mb-2 m-0 fw-bold">Customer List</p>
            <div className='card-body p-1'>
              <table className="table">
                <thead>
                  <tr className="text-center">
                    <th className="p-1 text-start">Name</th>
                    <th className="p-1">No Akaun</th>
                    <th className="p-1">No Harta</th>
                    <th className="p-1">Caj</th>
                    <th className="p-1">Tunggakan</th>
                    <th className="p-1">Bayaran</th>


                  </tr>
                </thead>
                <tbody>
                  {card?.customers.map((customer) =>
                    <tr className='my-2 text-center' >
                      <td className="p-1 text-start" style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '150px'
                      }}>{customer?.customer?.name}</td>
                      
                      <td className="p-1">{customer?.no_akaun_air}</td>
                      <td className="p-1">{customer?.customer?.no_harta}</td>
                      <td className="p-1">RM {customer?.amount}</td>
                      <td className="p-1">RM {customer?.customer?.arrears}</td>
                      <td className="p-1">RM {customer?.customer?.debt}</td>


                    </tr>
                  )}

                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-5">
          <Table2 />
        </div> */}
      </div>
    </div>
  );
}

export default Zone;
