import axios from "axios";
import { useState } from "react";
import Swal from 'sweetalert2';

const UploadRentalTunggakan = () => {
    const [files , setFiles] = useState();
    const [month, setMonth] = useState();

    const selectMonth = (e) =>{
      
        setMonth(e.target.value)
    }

    const changeFile = (e) =>{
       
        setFiles(e.target.files[0])
    }
    
    
    const handleSubmit = () =>{
        const formData = new FormData();
        
            formData.append("xlsx", files);
            formData.append("date", month)

        axios.post(
            // "http://bpohasil.click:8080/api/v1/upload/rental-rent"
            "http://bpohasil.click:8080/upload/rental_tunggakan_upload"
            , formData
            ,{
            headers:{
                "Content-Type": "multipart/form-data",
            },
        }
        
    )
        .then((response) =>{
            Swal.fire({
                title: 'Done Upload',
                text: 'Tunggakan Successfully Uploaded',
                icon: 'success',
                confirmButtonText: 'OK'
              })
        }).catch((error) =>{
            Swal.fire({
                title: 'Error!',
                text: 'There is Error in Uploading Tunggakan',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        })
    }
    return (
        <div className="card">
            <div className="card-body">
                <h4>
                    Upload CBR
                </h4>
                <div className="card-title d-flex justify-content-between aling-items-center">
                    <span>
                        <input type="file" onChange={changeFile} />
                    </span>
                    <span>
                        <input className="formControl" onChange={selectMonth} type="month" />
                           
                    </span>
                    <span>
                        <button className="btn btn-primary" onClick={handleSubmit}>Uploade</button>
                    </span>
                </div>
            </div>
        </div>
      );
}
 
export default UploadRentalTunggakan;