import { NavLink } from "react-router-dom";

const UploaderLinks = ({toggleItem,expandedItems,setMainTitle}) => {
    return ( 
        <li className="nav-item">
        <p
          className="fw-bold title mb-1 d-flex align-items-center justify-content-between"
          style={{ color: 'gray', cursor: 'pointer' }}
          onClick={() => {
            toggleItem('f1')
            setMainTitle('uploader')
          }}
        >
          <span className="fw-light" style={{color: "black"}}>
          <i className="me-2 fas fa-cloud-arrow-up" style={{ color: "orange" }}></i>UPLOADER
          </span>
          <i
            className={`fas ${expandedItems['f1'] ? 'fa-chevron-up' : 'fa-chevron-down'}`}
            style={{ fontSize: '.7rem' }}
          ></i>
        </p>
        {expandedItems['f1'] && (
          <ul className="nav flex-column ms-3">
          <li>
                 <NavLink to="/uploaderAir" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                  AIR
                </NavLink>
          </li>
          <li>
                 <NavLink to="/uploaderSewa" className="ps-2 fw-light my-1 text-secondary text-sm nav-link">
                  SEWA
                </NavLink>
          </li>

            
          </ul>)}
      </li>
     );
}
 
export default UploaderLinks;