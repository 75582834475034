import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const UploadWaterBayaran = () => {
    const [files, setFiles] = useState();
    const [loading, setLoading] = useState(false);
    const [month, setMonth] = useState();

    const changeFile = (e) => {
        try {
            setFiles(e.target.files)
        } catch (error) {
            Swal.fire({
                title: 'Uploading',
                text: 'Please select the file',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        }
        
    }
   
    const selectMonth = (e) =>{
      
        setMonth(e.target.value)
    }
    
    const handleSubmit = () => {
        const formData = new FormData();
        
        try {
            formData.append("xlsx", files[0])
        formData.append("date", month)
        } catch (error) {
            Swal.fire({
                title: 'No Fie to Upload',
                text: 'Please select the file',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        }

        if(loading === false){
            Swal.fire({
                title: 'Uploading',
                text: 'Bayaran File is Uploading \n Please Wait...',
                icon: 'info',
                confirmButtonText: 'OK'
              })
        }
        axios.post(
            "http://bpohasil.click:8080/upload/water_bayaran_upload"
            , formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            body:{
                date: month
            }
        })
            .then((response) => {
                setLoading(true);
                Swal.fire({
                    title: 'Done Upload',
                    text: 'Bills Successfully Uploaded',
                    icon: 'success',
                    confirmButtonText: 'OK'
                })
            }).catch((error) => {
                Swal.fire({
                    title: 'Error!',
                    text: 'There is Error in Uploading Bills',
                    icon: 'error',
                    confirmButtonText: 'OK'
                })
            })
    }
    return (
        <div className="card">
            <div className="card-body">
                <h4>
                    Upload Bayaran
                </h4>
                <div className="card-title d-flex justify-content-between aling-items-center">
                    <span>
                        <input type="file" onChange={changeFile} />
                    </span>
                    <span>
                            <input className="formControl" onChange={selectMonth} type="month" />
                           
                        </span>
                    <span>
                        <button className="btn btn-primary" onClick={handleSubmit}>Upload</button>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default UploadWaterBayaran;