import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";

const UploadRentalBayaran = () => {
    const [files , setFiles] = useState();
    const [month, setMonth] = useState();

    const selectMonth = (e) =>{
      
        setMonth(e.target.value)
    }

    const changeFile = (e) =>{
       
        setFiles(e.target.files)
    }
    
    const handleSubmit = () =>{
        const formData = new FormData();
        for(const file of files){
            formData.append("xlsx", file);
        }
        formData.append("date", month)

        axios.post(
            // "http://bpohasil.click:8080/api/v1/upload/rental-rent"

            "http://bpohasil.click:8080/upload/rental_bayaran_upload"
            , formData,{
            headers:{
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) =>{
            Swal.fire({
                title: 'Done Upload',
                text: 'Bills Successfully Uploaded',
                icon: 'success',
                confirmButtonText: 'OK'
              })
        }).catch((error) =>{
            Swal.fire({
                title: 'Error!',
                text: 'There is Error in Uploading Bills',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        })
    }
    return (
        <div className="card">
            <div className="card-body">
                <h4>
                    Upload Bayaran
                </h4>
                <div className="card-title d-flex justify-content-between aling-items-center">
               
                    <span>
                        <input type="file" onChange={changeFile} />
                    </span>
                    <span>
                        <input className="formControl" onChange={selectMonth} type="month" />
                           
                    </span>
                    <span>
                        <button className="btn btn-primary" onClick={handleSubmit}>Uploade</button>
                    </span>
                </div>
            </div>
        </div>
      );
}
 
export default UploadRentalBayaran;