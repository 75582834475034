import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderCard from "./SliderCard";

function SimpleSlider({ 
  tungakaanWater, 
  bayaraanWater, 
  cajJulaiWater, 
  akaunWater, 
  tungakaanRental, 
  cajJulaiRental, 
  bayaraanRental, 
  akaunRental, 
}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {/* Slide 1: Tungakaan */}
        <div>
          <SliderCard
            title="JUMLAH TUNGGAKAN"
            water={tungakaanWater}
            rental={tungakaanRental}
          />
        </div>

        {/* Slide 2: Bayaran */}
        <div>
          <SliderCard
            title="JUMLAH BAYARAN"
            water={bayaraanWater}
            rental={bayaraanRental}
          />
        </div>

        {/* Slide 3: Caj Julai */}
        <div>
          <SliderCard
            title="JUMLAH CAJ"
            water={cajJulaiWater}
            rental={cajJulaiRental}
          />
        </div>
        {/* Slide 3: akaun Julai */}

        <div>
          <SliderCard
            title="JUMLAH AKAUN"
            water={akaunWater}
            rental={akaunRental}
          />
        </div>
      </Slider>
    </div>
  );
}

export default SimpleSlider;
