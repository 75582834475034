import axios from "axios";
import { useState } from "react";
import Swal from 'sweetalert2';

const UploadWaterTunggakan = () => {
    const [files , setFiles] = useState();

    const changeFile = (e) =>{
       
        setFiles(e.target.files)
    }
    const [month, setMonth] = useState();
    const selectMonth = (e) =>{
       
        setMonth(e.target.value)
    }
    
    const handleSubmit = () =>{
        let loading = false;
        const formData = new FormData();
        if(loading === false){
            Swal.fire({
                title: 'Uploading',
                text: 'Tunggakan File is Uploading \n Please Wait...',
                icon: 'info',
                confirmButtonText: 'OK'
              })
        }
            try {
                formData.append("xlsx", files[0]);
                formData.append("date", month)
            } catch (error) {
                Swal.fire({
                    title: 'Error!',
                    text: 'There is Error in Uploading Tunggakan',
                    icon: 'error',
                    confirmButtonText: 'OK'
                  })
            }
            
        
        
        axios.post(
            
            "http://bpohasil.click:8080/upload/water_tunggakan_upload"
            , formData,{
            headers:{
                "Content-Type": "multipart/form-data",
            },
            body:{
                date: month
            }
        }
        
    )
        .then((response) =>{
            loading = true
            Swal.fire({
                title: 'Done Upload',
                text: 'Tunggakan Successfully Uploaded',
                icon: 'success',
                confirmButtonText: 'OK'
              })
        }).catch((error) =>{
            Swal.fire({
                title: 'Error!',
                text: 'There is Error in Uploading Tunggakan',
                icon: 'error',
                confirmButtonText: 'OK'
              })
        })
    }
    return (
        <div className="card">
            <div className="card-body">
                <h4>
                    Upload CBR
                </h4>
                <div className="card-title d-flex justify-content-between aling-items-center">
                        <span>
                        <input type="file" onChange={changeFile} />
                        </span>
                        <span>
                           
                            <input className="formControl" onChange={selectMonth} type="month" />
                           
                        </span>
                    <span>
                    
                        <button className="btn btn-primary" onClick={handleSubmit}>Uploade</button>
                    </span>
                </div>
            </div>
        </div>
      );
}
 
export default UploadWaterTunggakan;